import classNames from "classnames";
import "./Button.css";

function Button(props) {
  const { state, className, buttonType = "text", ...rest } = props;
  /** @type {string} Button combined CSS classnames */
  let btnClass = classNames(
    "Button",
    {
      "Button--text": buttonType === "text",
      "Button--textDanger": buttonType === "text" && state === "danger",
      "Button--textAccept": buttonType === "text" && state === "accept",
      "Button--isIcon": buttonType === "icon",
    },
    className
  );

  return (
    <button className={btnClass} {...rest}>
      {rest.children}
    </button>
  );
}

export default Button;
